.partner {
  width: 100%;
  background: var(--color-greyF7);
  border-radius: 14px;
  padding: 16px 24px;
  margin-top: 24px;
}

.email {
  background: var(--color-background);
  border-radius: 14px;
  padding: 12px 16px;
  margin-top: 32px;
}
.success {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .partner {
    padding: 12px;
    margin-top: 20px;
  }
  .success {
    padding: 0 16px;
  }
}
