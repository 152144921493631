$Normal: 400;
$DemiBold: 500;
$Bold: 600;
$baseWidth: 1128px;

$high: 1000;
$header: 100000;

$fontUniversalSans: 'UniversalSans';

$inputBoxShadowFocus: -2px -2px 4px rgba(64, 46, 82, 0.2), 2px 2px 4px rgba(64, 46, 82, 0.2);
$inputBoxShadowError: -2px -2px 4px rgba(140, 46, 41, 0.2), 2px 2px 4px rgba(140, 46, 41, 0.2);

.typography_h1 {
  font-size: 46px;
  font-weight: $Bold;
  color: var(--color-purple_6);
  line-height: 48px;
  letter-spacing: normal;
  font-family: 'UniversalSans';
}

.typography_h2 {
  font-size: 42px;
  font-weight: $Bold;
  color: var(--color-purple_6);
  line-height: 44px;
  font-family: 'UniversalSans';
}

.typography_h3 {
  font-size: 38px;
  font-weight: $Bold;
  color: var(--color-purple_6);
  line-height: 40px;
  font-family: 'UniversalSans';
}

.typography_h4 {
  font-size: 32px;
  font-weight: $Bold;
  color: var(--color-purple_6);
  line-height: 36px;
  font-family: 'UniversalSans';
}

.typography_h5 {
  font-size: 22px;
  font-weight: $Bold;
  color: var(--color-purple_6);
  line-height: 24px;
  font-family: 'UniversalSans';
}

.typography_h6 {
  font-size: 26px;
  font-weight: $Bold;
  color: var(--color-purple_6);
  line-height: 30px;
  font-family: 'UniversalSans';
}

.typography_h7 {
  font-size: 20px;
  font-weight: $Bold;
  color: var(--color-purple_6);
  line-height: 24px;
  font-family: 'UniversalSans';
}

.typography_h8 {
  font-size: 16px;
  font-weight: $Bold;
  color: var(--color-purple_6);
  line-height: 24px;
  font-family: 'UniversalSans';
}

.typography_h9 {
  font-size: 14px;
  font-weight: $Bold;
  color: var(--color-purple_6);
  line-height: 24px;
  font-family: 'UniversalSans';
}

.typography_h12 {
  font-size: 12px;
  font-weight: $Bold;
  color: var(--color-purple_6);
  line-height: 20px;
  font-family: 'UniversalSans';
}

.typography_h18 {
  font-size: 18px;
  font-weight: $Bold;
  color: var(--color-purple_6);
  line-height: 24px;
  font-family: 'UniversalSans';
}

.typography_body1 {
  font-size: 18px;
  color: var(--color-purple_6);
  line-height: 26px;
}

.typography_body2 {
  font-size: 16px;
  color: var(--color-purple_6);
  line-height: 24px;
}

.typography_body3 {
  font-size: 14px;
  color: var(--color-purple_6);
  line-height: 24px;
}

.typography_meta {
  font-size: 12px;
  color: var(--color-purple_6);
  line-height: 16px;
}

.typography_label {
  font-size: 11px;
  color: var(--color-purple_6);
  line-height: 1.45;
}
