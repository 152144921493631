body.coliving {
  --color-purple_1: #222023; //yellowBg
  --color-purple_2: #373132;
  --color-purple_3: #4a4242;
  --color-purple_4: #675955; //yellowMedium
  --color-purple_5: #8d7870;
  --color-purple_6: #b3978a; //yellow
  --color-purple_8: #ddd6d0; //yellowDark

  --color-green_1: #19241a; //green Bg
  --color-green_6: #5eb05e; //green
  --color-green_7: #8bc589;

  --color-gray_1: #11141a;
  --color-gray_3: #2e3035;
  --color-gray_4: #35373c;
  --color-gray_5: #515358;
  --color-gray_6: #64666a;
  --color-gray_7: #949598;
  --color-gray_10: #f1f1f1;

  --color-red_1: #2a1819; //redBg
  --color-red_2: #422021;
  --color-red_4: #773736; //redMedium
  --color-red_6: #d05654; //red

  --color-yellow_1: #292523; //orangeBg
  --color-yellow_2: #423b31; //orange_2
  --color-yellow_4: #7b6b52; //orangeMedium
  --color-yellow_6: #d9b883; //orange
  --color-yellow_8: #f1e7cd; //orangeDark

  --color-pink_1: #25161d;

  --color-blue_6: #1a61a4;

  --color-outline: #373132;
  --color-background: #2e3035;
  --color-transparent: transparent;
  --color-black3B: #dbdcdd;
  --color-greyEb: #35373c;
  --color-violet: #d9dfe7;
  --color-grey_Cc: #515358;
  --color-grey_99: #949598;
  --color-greyFa: #24272c;
  --color-greyF7: #2e3035;
  --color-greyText: #949598;
  --color-grey4c: #b3978a;
  --color-tundora: #11141a;
  --color-white: #ffffff;

  --color—btn-textlink-default: #8295bc;
  --color—btn-textlink-hover: #677796;

  --color—footer-text: #b3978a;
  --color—footer-bg: #121e35;

  .tippy-tooltip {
    background-color: var(--color-purple_6);
  }
}
