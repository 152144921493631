.booking-process-layout {
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.booking-progress__left {
  width: 720px;
}
.booking-progress__left-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.booking-progress__right {
  width: 360px;
}
.booking-progress__right-container {
  padding: 16px 16px 28px;
  border-radius: 14px;
  border: solid 1px var(--color-purple_2);
}
.booking-progress__right-title {
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
}
.booking-progress__right-divider {
  display: block;
  margin: 28px 0 !important;
}
.booking-progress__right-location {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.booking-progress__right-location-icon {
  width: 12px;
  height: 16px;
  margin-right: 4px;
}
.booking-progress__right-location-icon > path {
  fill: var(--color-purple_6);
}
.booking-progress__m-price {
  background: var(--color-greyFa);
}
@media screen and (max-width: 768px) {
  .booking-progress__left {
    width: 100%;
  }
}
