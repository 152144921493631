body.beyoo {
  --color-purple_1: #e8f1f2; //yellowBg
  --color-purple_2: #bacfd6;
  --color-purple_3: #9ab1b8;
  --color-purple_4: #608399; //yellowMedium
  --color-purple_5: #415b6e;
  --color-purple_6: #263645; //yellow
  --color-purple_8: #162430; //yellowDark

  --color-green_1: #e7f8f5; //green Bg
  --color-green_6: #1cb89e; //green
  --color-green_7: #169e8c;

  --color-gray_1: #ffffff;
  --color-gray_3: #f5f5f5;
  --color-gray_4: #f0f0f0;
  --color-gray_5: #d9d9d9;
  --color-gray_6: #bfbfbf;
  --color-gray_7: #8c8c8c;
  --color-gray_10: #262626;

  --color-red_1: #fbedef; //redBg
  --color-red_2: #f6d4d9;
  --color-red_4: #e88e9a; //redMedium
  --color-red_6: #db4b5e; //red

  --color-yellow_1: #fff5d6; //orangeBg
  --color-yellow_2: #ffebb8; //orange_2
  --color-yellow_4: #facc78; //orangeMedium
  --color-yellow_6: #de9d35; //orange
  --color-yellow_8: #ab7218; //orangeDark

  --color-pink_1: #fef4f6;

  --color-blue_6: #40afd1;

  --color-outline: #bfdae3;
  --color-background: #f2f2f2;
  --color-transparent: transparent;
  --color-black3B: #3b3b3b;
  --color-greyEb: #ebebeb;
  --color-violet: #408bd1;
  --color-grey_Cc: #cccccc;
  --color-grey_99: #999999;
  --color-greyFa: #fafafa;
  --color-greyF7: #f7f7f7;
  --color-greyText: #859094;
  --color-grey4c: #013c4c;
  --color-tundora: #4c4c4c;
  --color-white: #ffffff;

  --color—btn-textlink-default: #169e8c;
  --color—btn-textlink-hover: #108377;

  --color—footer-text: #ffffff;
  --color—footer-bg: #263645;
}
