body.ure {
  --color-purple_1: #e6eced; //yellowBg
  --color-purple_2: #c2d0d4;
  --color-purple_3: #92abb2;
  --color-purple_4: #5f848e; //yellowMedium
  --color-purple_5: #8d7870;
  --color-purple_6: #2f5f6c; //yellow
  --color-purple_8: #012b36; //yellowDark

  --color-green_1: #e7f8f2; //green Bg
  --color-green_6: #12b878; //green
  --color-green_7: #0f9c66;

  --color-gray_1: #ffffff;
  --color-gray_3: #f5f5f5;
  --color-gray_4: #f0f0f0;
  --color-gray_5: #d9d9d9;
  --color-gray_6: #bfbfbf;
  --color-gray_7: #8c8c8c;
  --color-gray_10: #262626;

  --color-red_1: #fbedf0; //redBg
  --color-red_2: #f6d4db;
  --color-red_4: #e88ea0; //redMedium
  --color-red_6: #db4b68; //red

  --color-yellow_1: #f9f4e7; //orangeBg
  --color-yellow_2: #f0e5c6; //orange_2
  --color-yellow_4: #d9bc69; //orangeMedium
  --color-yellow_6: #c29411; //orange
  --color-yellow_8: #8a690c; //orangeDark

  --color-pink_1: #fef4f6;

  --color-blue_6: #408bd1;

  --color-outline: #c2d0d4;
  --color-background: #f2f2f2;
  --color-transparent: transparent;
  --color-black3B: #3b3b3b;
  --color-greyEb: #ebebeb;
  --color-violet: #408bd1;
  --color-grey_Cc: #cccccc;
  --color-grey_99: #999999;
  --color-greyFa: #fafafa;
  --color-greyF7: #f7f7f7;
  --color-greyText: #859094;
  --color-grey4c: #013c4c;
  --color-tundora: #4c4c4c;
  --color-white: #ffffff;

  --color—btn-textlink-default: #c29411;
  --color—btn-textlink-hover: #ba2c4d;

  --color—footer-text: #ffffff;
  --color—footer-bg: #c29411;
}
