.phonecode-select__menu {
  width: 200px !important;
  overflow: hidden;
}
.select-root-prefix__menu {
  overflow: hidden;
}

.select-multi-component-container .select-multi-value:nth-last-child(2) .select-multi-span {
  display: none;
}
