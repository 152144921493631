body #scom-unique-payment-page {
  --color-main-main-color-1: #fff8da;
  --color-main-main-color-5: #fedd48;
  --color-main-main-color-6: #fcce01;
  --color-main-main-color-8: #f9a600;

  --color-gray-gray-color-1: #ffffff;
  --color-gray-gray-color-2: #f8f8f6;
  --color-gray-gray-color-3: #f8f8f6;
  --color-gray-gray-color-4: #e7e7e7;
  --color-gray-gray-color-5: #c8c9cb;
  --color-gray-gray-color-6: #9e9e9e;
  --color-gray-gray-color-7: #63656a;
  --color-gray-gray-color-8: #4c4c4c;

  --color-yellow-yellow-color-1: #fff8da;
  --color-yellow-yellow-color-6: #fcce01;

  --color-green-green-color-1: #e2f4f2;
  --color-green-green-color-5: #52ccc0;
  --color-green-green-color-6: #38b2a6;
  --color-green-green-color-7: #2b948a;

  --color-red-red-color-1: #fbe9e8;
  --color-red-red-color-5: #e16b5e;
  --color-red-red-color-6: #d95449;
  --color-red-red-color-7: #d21e1f;

  --color-blue-blue-color-1: #eaecf4;
  --color-blue-blue-color-5: #98a7d3;
  --color-blue-blue-color-6: #6d7fb4;
  --color-blue-blue-color-7: #445a98;

  --color-orange-orange-color-1: #ffede4;
  --color-orange-orange-color-3: #fbb491;
  --color-orange-orange-color-5: #fd8649;
  --color-orange-orange-color-6: #f46e42;
  --color-orange-orange-color-7: #f25202;

  --color-alpha-dark-alpha-dark-40: #4c4c4c66;
  --color-alpha-dark-alpha-dark-60: #4c4c4c99;
  --color-alpha-dark-alpha-dark-70: #4c4c4cb2;
  --color-alpha-dark-alpha-dark-75: #4c4c4cbf;
  --color-alpha-dark-alpha-dark-90: #4c4c4ce5;

  --color-alpha-white-alpha-white-5: #ffffff0d;
  --color-alpha-white-alpha-white-10: #ffffff1a;
  --color-alpha-white-alpha-white-20: #ffffff33;
  --color-alpha-white-alpha-white-40: #ffffff66;
  --color-alpha-white-alpha-white-50: #ffffff80;
  --color-alpha-white-alpha-white-70: #ffffffb2;

  --color-alpha-green-alpha-green-40: #38b2a666;
  --color-alpha-green-alpha-green-70: #38b2a6b2;

  --color-alpha-main-color-alpha-main-color-40: #fcce0166;
  --color-alpha-main-color-alpha-main-color-50: #fcce0180;
  --color-alpha-main-color-alpha-main-color-70: #fcce01b2;

  --color-static-white: #ffffff;
  --color-static-black: #000000;

  --size-size-0: 0px;
  --size-size-025: 1px;
  --size-size-050: 2px;
  --size-size-100: 4px;
  --size-size-150: 6px;
  --size-size-200: 8px;
  --size-size-300: 10px;
  --size-size-350: 14px;
  --size-size-400: 16px;
  --size-size-450: 18px;
  --size-size-500: 20px;
  --size-size-600: 24px;
  --size-size-700: 28px;
  --size-size-800: 32px;
  --size-size-900: 36px;
  --size-size-1000: 40px;
  --size-size-1200: 48px;
  --size-size-1400: 56px;
  --size-size-1600: 64px;
  --size-size-1800: 72px;
  --size-size-2000: 80px;
  --size-size-2200: 88px;
  --size-size-2400: 96px;
  --size-size-2600: 108px;
  --size-size-2800: 112px;
  --size-size-3000: 120px;
  --size-size-3200: 128px;

  --radius-radius-full: '99px';
  --radius-radius-0: var(--size-size-0);
  --radius-radius-s: var(--size-size-050);
  --radius-radius-m: var(--size-size-100);
  --radius-radius-l: var(--size-size-200);
  --radius-radius-xl: var(--size-size-300);
  --radius-radius-xxl: var(--size-size-500);

  --color-text-color-text: var(--color-gray-gray-color-8);
  --color-text-color-text-secondary: var(--color-gray-gray-color-7);
  --color-text-color-text-tertiary: var(--color-gray-gray-color-6);
  --color-text-color-text-invert: var(--color-gray-gray-color-1);
  --color-text-color-text-invert-secondary: var(--color-gray-gray-color-5);
  --color-text-color-text-invert-tertiary: var(--color-gray-gray-color-5);
  --color-text-color-text-emphasis: var(--color-orange-orange-color-7);
  --color-text-color-text-selected: var(--color-green-green-color-6);
  --color-text-color-text-selected-inverse: var(--color-gray-gray-color-1);
  --color-text-color-text-link: var(--color-action-secondary-color-action-secondary);
  --color-text-color-text-link-hover: var(--color-action-color-action-secondary-hover);
  --color-text-color-text-brand: var(--color-gray-gray-color-8);
  --color-text-color-text-price: var(--color-text-color-text-emphasis);

  --color-accent-color-text-red: var(--color-red-red-color-7);
  --color-accent-color-text-yellow: var(--color-yellow-yellow-color-6);
  --color-accent-color-text-green: var(--color-green-green-color-7);
  --color-accent-color-text-blue: var(--color-blue-blue-color-7);
  --color-accent-color-text-gray: var(--color-gray-gray-color-7);

  --color-bg-color-bg: var(--color-gray-gray-color-1);
  --color-bg-color-bg-secondary: var(--color-gray-gray-color-2);
  --color-bg-color-bg-tertiary: var(--color-gray-gray-color-2);
  --color-bg-color-bg-elevated: var(--color-gray-gray-color-1);
  --color-bg-color-bg-backdrop: var(--color-alpha-dark-alpha-dark-75);
  --color-bg-color-bg-tooltips: var(--color-alpha-dark-alpha-dark-90);
  --color-bg-color-bg-inverse: var(--color-gray-gray-color-8);
  --color-bg-color-bg-selected: var(--color-green-green-color-6);
  --color-bg-color-bg-surface-brand: var(--color-main-main-color-1);
  --color-bg-color-bg-fill-brand: var(--color-main-main-color-6);
  --color-bg-color-bg-fill-brand_2: var(--color-green-green-color-6);
  --color-bg-color-bg-fill-emphasis: var(--color-green-green-color-6);
  --color-bg-accent-color-bg-fill-red: var(--color-red-red-color-6);
  --color-bg-accent-color-bg-fill-yellow: var(--color-yellow-yellow-color-6);
  --color-bg-accent-color-bg-fill-green: var(--color-green-green-color-6);
  --color-bg-accent-color-bg-fill-blue: var(--color-blue-blue-color-6);
  --color-bg-accent-color-bg-fill-gray: var(--color-gray-gray-color-7);
  --color-bg-accent-color-bg-surface-red: var(--color-red-red-color-1);
  --color-bg-accent-color-bg-surface-yellow: var(--color-yellow-yellow-color-1);
  --color-bg-accent-color-bg-surface-green: var(--color-green-green-color-1);
  --color-bg-accent-color-bg-surface-blue: var(--color-blue-blue-color-1);
  --color-bg-accent-color-bg-surface-gray: var(--color-gray-gray-color-2);
  --color-bg-accent-color-bg-surface-gray_2: var(--color-gray-gray-color-3);

  --color-icon-color-icon: var(--color-green-green-color-6);
  --color-icon-color-icon-secondary: var(--color-gray-gray-color-7);
  --color-icon-color-icon-tertiary: var(--color-gray-gray-color-6);
  --color-icon-color-icon-inverse: var(--color-gray-gray-color-1);
  --color-icon-color-icon-emphasis: var(--color-red-red-color-6);
  --color-icon-color-icon-two-tone-1: var(--color-gray-gray-color-8);
  --color-icon-color-icon-two-tone-2: var(--color-bg-accent-color-bg-fill-yellow);

  --color-border-color-border: var(--color-gray-gray-color-4);
  --color-border-color-border-inverse: var(--color-alpha-white-alpha-white-20);

  --color-warning-color-warning-bg: var(--color-red-red-color-1);
  --color-warning-color-warning-text: var(--color-red-red-color-7);
  --color-warning-color-warning-icon: var(--color-red-red-color-6);

  --color-success-color-success-bg: var(--color-green-green-color-1);
  --color-success-color-success-text: var(--color-green-green-color-7);
  --color-success-color-success-icon: var(--color-green-green-color-6);

  --color-danger-color-danger-bg: var(--color-red-red-color-1);
  --color-danger-color-danger-text: var(--color-red-red-color-7);
  --color-danger-color-danger-icon: var(--color-red-red-color-6);

  --color-shadow-color-shadow-1: #0000001a;

  --color-action-primary-color-action-primary: var(--color-main-main-color-6);
  --color-action-primary-color-action-primary-hover: var(--color-main-main-color-5);
  --color-action-primary-color-action-primary-pressed: var(--color-main-main-color-5);
  --color-action-primary-color-action-primary-disabled: var(--color-gray-gray-color-4);
  --color-action-primary-color-action-primary-inverse: var(--color-gray-gray-color-1);
  --color-action-primary-color-action-primary-inverse-hover: var(
    --color-alpha-white-alpha-white-70
  );
  --color-action-primary-color-action-primary-inverse-pressed: var(
    --color-alpha-white-alpha-white-70
  );
  --color-action-primary-color-action-primary-inverse-disabled: var(
    --color-alpha-white-alpha-white-40
  );

  --color-action-secondary-color-action-secondary: var(--color-green-green-color-6);
  --color-action-secondary-color-action-secondary-hover: var(--color-green-green-color-5);
  --color-action-secondary-color-action-secondary-pressed: var(--color-green-green-color-5);
  --color-action-secondary-color-action-secondary-disabled: var(--color-gray-gray-color-6);

  --color-action-tertiary-color-action-tertiary: var(--color-gray-gray-color-6);
  --color-action-tertiary-color-action-tertiary-hover: var(--color-gray-gray-color-7);
  --color-action-tertiary-color-action-tertiary-pressed: var(--color-gray-gray-color-7);
  --color-action-tertiary-color-action-tertiary-disabled: var(--color-gray-gray-color-5);

  --color-action-danger-color-action-danger: var(--color-red-red-color-6);
  --color-action-danger-color-action-danger-hover: var(--color-red-red-color-5);
  --color-action-danger-color-action-danger-border: var(--color-red-red-color-5);

  --radius-radius-modal: var(--radius-radius-xl);
  --radius-radius-button: var(--radius-radius-l);
  --radius-radius-input: var(--radius-radius-l);
  --radius-radius-control: var(--radius-radius-m);
  --radius-radius-card: var(--radius-radius-l);
  --radius-radius-popover: var(--radius-radius-l);
  --radius-radius-line: var(--radius-radius-l);
  --radius-radius-tag: var(--radius-radius-full);
  --radius-radius-banner: var(--radius-radius-l);
  --radius-radius-round: var(--radius-radius-full);

  --space-space-0: var(--size-size-0);
  --space-space-025: var(--size-size-025);
  --space-space-050: var(--size-size-050);
  --space-space-100: var(--size-size-100);
  --space-space-150: var(--size-size-150);
  --space-space-200: var(--size-size-200);
  --space-space-250: var(--size-size-250);
  --space-space-300: var(--size-size-300);
  --space-space-400: var(--size-size-400);
  --space-space-500: var(--size-size-500);
  --space-space-600: var(--size-size-600);
  --space-space-700: var(--size-size-700);
  --space-space-800: var(--size-size-800);
  --space-space-900: var(--size-size-900);
  --space-space-1000: var(--size-size-1000);
  --space-space-1200: var(--size-size-1200);
  --space-space-1400: var(--size-size-1400);
  --space-space-1600: var(--size-size-1600);
  --space-space-1800: var(--size-size-1800);
  --space-space-2000: var(--size-size-2000);
  --space-space-2200: var(--size-size-2200);
  --space-space-2400: var(--size-size-2400);
  --space-space-2600: var(--size-size-2600);
  --space-space-2800: var(--size-size-2800);
  --space-space-3000: var(--size-size-3000);
  --space-space-3200: var(--size-size-3200);

  --space-special-margin-cards: var(--space-space-1000);
  --space-special-margin-inputs: var(--space-space-400);
  --space-special-margin-form: var(--space-space-700);
  --space-special-margin-buttons: var(--space-space-400);
  --space-special-margin-tabs: var(--space-space-0);
  --space-special-margin-popover: var(--space-space-100);
  --space-special-padding-table-cell: var(--space-space-0);
  --space-special-padding-icon-text-m: var(--space-space-200);
  --space-special-padding-icon-text-l: var(--space-space-300);
  --space-special-padding-icon-text-xl: var(--space-space-500);
  --space-special-padding-control-text: var(--space-special-padding-icon-text-m);

  --component-button-padding-component-button-large-top-bottom: var(--size-size-400);
  --component-button-padding-component-button-large-left-right: var(--space-space-1200);
  --component-button-padding-component-button-medium-left-right: var(--space-space-1000);
  --component-button-padding-component-button-medium-top-bottom: var(--space-space-300);

  --component-button-color-component-button-primary-bg-hover: var(
    --color-action-primary-color-action-primary-hover
  );
  --component-button-color-component-button-secondary-bg: var(--color-alpha-white-alpha-white-70);
  --component-button-color-component-button-secondary-bg-hover: var(--color-green-green-color-1);
  --component-button-color-component-button-secondary-border: var(
    --color-action-secondary-color-action-secondary
  );
  --component-button-color-component-button-secondary-bg-inverse-hover: var(
    --color-alpha-white-alpha-white-20
  );
  --component-button-color-component-button-primary-text: var(--color-text-color-text);
  --component-button-color-component-button-secondary-text: var(
    --color-action-secondary-color-action-secondary
  );
  --component-button-color-component-button-primary-text-inverse: var(
    --color-action-primary-color-action-primary
  );
  --component-button-color-component-button-secondary-text-inverse: var(
    --color-action-primary-color-action-primary-inverse
  );

  --component-input-color-component-input-bg: var(--color-gray-gray-color-1);
  --component-input-color-component-input-bg-hover: var(--color-gray-gray-color-1);
  --component-input-color-component-input-bg-focus: var(--color-gray-gray-color-1);
  --component-input-color-component-input-bg-disabled: var(--color-gray-gray-color-2);
  --component-input-color-component-input-border: var(--color-gray-gray-color-5);
  --component-input-color-component-input-border-hover: var(--color-gray-gray-color-7);
  --component-input-color-component-input-border-focus: var(--color-gray-gray-color-7);
  --component-input-color-component-input-border-disabled: var(--color-gray-gray-color-4);
  --component-input-color-component-input-text-placeholder: var(--color-text-color-text-tertiary);
  --component-input-color-component-input-text-default: var(--color-text-color-text);
  --component-input-color-component-input-text-disabled: var(--color-gray-gray-color-5);
  --component-input-color-component-input-icon: var(--color-action-tertiary-color-action-tertiary);
  --component-input-color-component-input-icon-disabled: var(
    --color-action-tertiary-color-action-tertiary-disabled
  );

  --component-input-padding-component-input-top-bottom: var(--size-size-300);
  --component-input-padding-component-input-left-right: var(--size-size-300);
  --component-input-padding-component-input-text-icon: var(--size-size-300);
  --component-input-margin-component-input-error-hint: var(--size-size-100);

  --component-control-color-component-control-bg: var(--color-gray-gray-color-1);
  --component-control-color-component-control-bg-hover: var(--color-gray-gray-color-1);
  --component-control-color-component-control-bg-selected: var(--color-green-green-color-6);
  --component-control-color-component-control-bg-selected-hover: var(--color-green-green-color-5);
  --component-control-color-component-control-bg-disabled: var(--color-gray-gray-color-4);
  --component-control-color-component-control-bg-selected-disabled: var(
    --color-green-green-color-6
  );
  --component-control-color-component-control-border: var(
    --color-action-tertiary-color-action-tertiary
  );
  --component-control-color-component-control-border-hover: var(--color-green-green-color-5);
  --component-control-color-component-control-border-selected: var(--color-green-green-color-6);
  --component-control-color-component-control-border-disabled: var(--color-gray-gray-color-4);
  --component-control-color-component-control-icon: var(--color-icon-color-icon-inverse);
  --component-control-color-component-control-icon-disabled: var(--color-icon-color-icon-inverse);

  --component-card-color-component-card-bg: var(--color-bg-color-bg);
  --component-card-color-component-card-border: var(--color-border-color-border);

  --app-color-app-header-bg: var(--color-bg-color-bg-fill-brand);
  --app-color-app-nav-bg: var(--color-bg-color-bg-elevated);

  --app-margin-page-top-l: var(--size-size-800);
  --app-margin-page-top-m: var(--size-size-300);
  --app-margin-page-left-right: var(--size-size-400);
  --app-margin-page-bottom: var(--size-size-1400);
  --app-margin-title-content: var(--size-size-600);
  --app-margin-title-subtitle: var(--size-size-100);
  --app-margin-cards-l: var(--size-size-1000);
  --app-margin-cards-m: var(--size-size-600);
  --app-margin-cards-s: var(--size-size-400);
  --app-padding-card-m-left-right: var(--space-space-500);
  --app-padding-card-m-top-bottom: var(--space-space-400);
}
