body {
  --color-purple_1: #eceaee; //yellowBg
  --color-purple_2: #d9d5dc;
  --color-purple_3: #b2abb9;
  --color-purple_4: #8c8197; //yellowMedium
  --color-purple_5: #655774;
  --color-purple_6: #3f2d51; //yellow
  --color-purple_8: #2c1f39; //yellowDark

  --color-green_1: #edfcf8; //green Bg
  --color-green_6: #4fe0be; //green
  --color-green_7: #43bea1;

  --color-gray_3: #f5f5f5;
  --color-gray_4: #f0f0f0;
  --color-gray_5: #d9d9d9;
  --color-gray_6: #bfbfbf;
  --color-gray_7: #8c8c8c;
  --color-gray_10: #262626;

  --color-red_1: #fdeeed; //redBg
  --color-red_2: #ffdddb;
  --color-red_4: #fe9892; //redMedium
  --color-red_6: #fe544a; //red

  --color-yellow_1: #fef8ec; //orangeBg
  --color-yellow_2: #fff3d9; //orange_2
  --color-yellow_4: #ffdc8e; //orangeMedium
  --color-yellow_6: #ffc543; //orange
  --color-yellow_8: #b38a2f; //orangeDark

  --color-pink_1: #fdf3f5;

  --color-blue_6: #2a41ea;

  --color-outline: #c5c0ca;
  --color-background: #f5f6f7;
  --color-transparent: transparent;
  --color-black3B: #3b3b3b;
  --color-greyEb: #ebebeb;
  --color-violet: #475e9f;
  --color-grey_Cc: #cccccc;
  --color-grey_99: #999999;
  --color-greyFa: #fafafa;
  --color-greyF7: #f7f7f7;
  --color-greyText: #859094;
  --color-grey4c: #3f2d51;
  --color-tundora: #4c4c4c;
  --color-white: #ffffff;

  .tippy-tooltip {
    background-color: var(--color-purple_6);
  }

  margin: 0;
}
